import { Passenger } from "../../../../services/openapi";
import { FC, useEffect, useState } from "react";
import { Button, Grid, TableCell, TableHead } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Report } from "@mui/icons-material"; // Import the Report icon
import {
  approvePassengersTableAction,
  confirmPassengerAction,
  confirmPassengersTableAction,
} from "../../../../redux/slices/actions/salesActions";
import useAuth from "../../../../hooks/useAuth";
import { AppDispatch, RootState } from "../../../../redux/store";
import ComparisonModal from "../../../../components/ComparisonModal";

interface InterfacePassengersTable {
  passengers: Passenger[];
}

const PassengersTable: FC<InterfacePassengersTable> = ({ passengers }) => {
  const { role } = useAuth();
  const { passengers_confirmed, passengers_approved } = useSelector(
    (state: RootState) => state.sales.selectedAirfare
  );
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure day is 2 digits
    const month = date
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase(); // Abbreviated month in uppercase
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}${month}${year}`;
  };
  const [confirmPassengers, setConfirmPassengers] =
    useState<boolean>(passengers_confirmed);
  const [confirmedPassenger, setConfirmedPassenger] = useState<Array<boolean>>(
    []
  );
  const [airfareId, setAirFareId] = useState<number>(0);
  const [showConfirmTable, setShowConfirmTable] = useState<boolean>(false);
  const [selectedPassenger, setSelectedPassenger] = useState<Passenger>(
    passengers[0]
  );
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const areAllConfirmed = passengers.every(
      (passenger) => passenger.confirmed
    );
    setConfirmPassengers(!areAllConfirmed);
    const initialConfirmedStatuses = passengers.map(
      (passenger) => passenger.confirmed
    );
    setAirFareId(passengers && passengers[0].airfareId);
    setConfirmedPassenger(initialConfirmedStatuses);
    if (passengers.length > 0) {
      setSelectedPassenger(passengers[0]);
    }
  }, [passengers]);

  useEffect(() => {
    const areAllConfirmed = confirmedPassenger.every((status) => status);
    if (areAllConfirmed && saleDetails.status === "inProgress") {
      setShowConfirmTable(areAllConfirmed);
    } else {
      setShowConfirmTable(false);
    }
  }, [confirmedPassenger]);

  const dispatch: AppDispatch = useDispatch();

  const confirmPassenger = (id: number, index: number) => {
    dispatch(confirmPassengerAction({ passengerId: id })).then(
      (actionResult) => {
        if (confirmPassengerAction.fulfilled.match(actionResult)) {
          // Action completed successfully
          setConfirmedPassenger((prevState) => {
            // Create a new array with the updated value
            const updatedConfirmedPassenger = [...prevState];
            updatedConfirmedPassenger[index] = !prevState[index];
            return updatedConfirmedPassenger;
          });
        } else if (confirmPassengerAction.rejected.match(actionResult)) {
          // Action failed
          console.error("Error performing action: ", actionResult.payload);
        }
      }
    );
    const areAllConfirmed = passengers.every(
      (passenger) => passenger.confirmed
    );
    setShowConfirmTable(areAllConfirmed);
  };

  const confirmPassengersTable = () => {
    dispatch(confirmPassengersTableAction({ airfareId }));
  };

  const approvePassengersTable = () => {
    dispatch(approvePassengersTableAction({ airfareId }));
  };

  const handleCellClick = (passenger: Passenger) => {
    if (
      passenger.need_to_check_first_name ||
      passenger.need_to_check_middle_name ||
      passenger.need_to_check_last_name
    ) {
      setSelectedPassenger(passenger);
      setOpenModal(true);
    }
  };

  return (
    <Grid container>
      <Grid item xs={11}>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>#</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>PTC</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>FIRST NAME</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>MID NAME</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>LAST NAME</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>DOB</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>GENDER</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>PASSPORT</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>EXPIRE</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>ISSUING COUNTRY</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>NATIONALITY</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>FF</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>REDRESS</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>KTN</TableCell>
                {saleDetails.status === "inProgress" && (
                  <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {passengers &&
                passengers.map((passenger, index) => (
                  <TableRow key={index}>
                    <TableCell>{passenger.id}</TableCell>
                    <TableCell>{passenger.ptc}</TableCell>
                    <TableCell onClick={() => handleCellClick(passenger)}>
                      {passenger.firstName}
                      {passenger.need_to_check_first_name && (
                        <Report sx={{ ml: 1, color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell onClick={() => handleCellClick(passenger)}>
                      {passenger.middleName}
                      {passenger.need_to_check_middle_name && (
                        <Report sx={{ ml: 1, color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell onClick={() => handleCellClick(passenger)}>
                      {passenger.lastName}
                      {passenger.need_to_check_last_name && (
                        <Report sx={{ ml: 1, color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell>{formatDate(passenger.dateOfBirth)}</TableCell>
                    <TableCell>{passenger.gender}</TableCell>
                    <TableCell>{passenger.passportNumber}</TableCell>
                    <TableCell>{passenger.expiringPassport}</TableCell>
                    <TableCell>{passenger.IssuingCountry}</TableCell>
                    <TableCell>{passenger.nationality}</TableCell>
                    <TableCell>
                      {passenger.frequentFlyer?.code ?? "-"}
                    </TableCell>
                    <TableCell>{passenger.redressCode}</TableCell>
                    <TableCell>
                      {passenger.ktn ? passenger.ktn?.code : "-"}
                    </TableCell>
                    {saleDetails.status === "inProgress" && (
                      <TableCell>
                        <Button
                          onClick={() => confirmPassenger(passenger.id, index)}
                        >
                          {confirmedPassenger[index] ? "Unconfirm" : "Confirm"}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {showConfirmTable && (role === "ticketing" || role === "manager") && (
        <Grid item xs={1} padding={2} display={"flex"} alignItems={"center"}>
          <Button variant={"contained"} onClick={confirmPassengersTable}>
            {passengers_confirmed ? "Unconfirm " : "Confirm "} Passengers
          </Button>
        </Grid>
      )}
      {saleDetails.status === "inApproving" &&
        (role === "bookkeping" || role === "manager") && (
          <Grid item xs={1} padding={2} display={"flex"} alignItems={"center"}>
            <Button variant={"contained"} onClick={approvePassengersTable}>
              {passengers_approved ? "Reject" : "Approve"} Passengers
            </Button>
          </Grid>
        )}
      {selectedPassenger && (
        <ComparisonModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          passenger={selectedPassenger}
        />
      )}
    </Grid>
  );
};

export default PassengersTable;
